import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';

import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BalanceIcon from '@mui/icons-material/Balance';
import EditIcon from '@mui/icons-material/Edit';

import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Card, CardContent, Icon, IconButton, ListItemText, Typography } from '@mui/material';

import "../../styles/Tasks.css";
import "../../styles/App.css";
import IconLibrary from '../utility/IconLibrary';

export const TagList: ITag[] = [
    {
        name: "Fitness",
        color: "#F78E69",
        icon: <FitnessCenterIcon />,
        iconKey: "FitnessCenter"
    },
    {
        name: "Arts",
        color: "#3E92CC",
        icon: <TheaterComedyIcon />,
        iconKey: "TheaterComedy"
    },
    {
        name: "Education",
        color: "#49A078",
        icon: <PsychologyIcon />,
        iconKey: "Psychology"
    },
    {
        name: "Lifestyle",
        color: "#CCFBFE",
        icon: <BalanceIcon />,
        iconKey: "Balance"
    }

];

export interface ITag {
    name: string,
    color: string,
    icon: React.ReactElement,
    iconKey: string
}

interface ITagSelectorProps {
    defaultValue?: ITag,
    ref: RefObject<HTMLInputElement>,
}

export const TagSelector: React.FC<ITagSelectorProps> = (props) => {
    const { defaultValue, ref } = props;

    /* Modal settings */
    const [open, setOpen] = useState(false);
    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const controlRef = useRef<HTMLDivElement>(null);

    return <>
        <div className="taskEditTagSelector" ref={controlRef}>
            <FormControl sx={{width: "50%"}}>
                <InputLabel>Category</InputLabel>
                <Select
                    defaultValue={defaultValue?.name}
                    label="Category"
                    inputRef={ref}
                >
                    { TagList.map((tag) => (
                    <MenuItem key={tag.name} value={tag.name}>
                        <ListItemText primary={tag.name} />
                    </MenuItem>
                    )) }
                </Select>
            </FormControl>
            <div onClick={handleOpen}>
            <IconButton>
               <EditIcon/>
            </IconButton>
            </div>
        </div>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box className="modalBox" sx={{
              bgcolor: 'background.paper',
              width: 800,
              boxShadow: 24,
            }}>
                <CardContent>
                    <TagEditForm />
                </CardContent>
            </Box>
      </Modal>
    </>;
}


interface ITagEditFormProps {
}

export const TagEditForm: React.FC<ITagEditFormProps> = (props) => {

    return <div>
        {TagList.map((tag: ITag) => <TagCard tag={tag} />)}
    </div>;
}


interface ITagCardProps {
    tag: ITag
}

export const TagCard: React.FC<ITagCardProps> = (props) => {
    const { tag } = props;
    
    return <Card sx={{ minWidth: 275 }}>
        <CardContent className="tagEditCard">
            <Typography variant='h5'>
                {tag.name}
            </Typography>
            <div className='tagEditColor' style={{backgroundColor: tag.color}}>
            </div>
            <div className='tagEditIconEditButton'>
                <IconControl tag={tag} />
            </div>
        </CardContent>
    </Card>;
}


interface IIconControlProps {
    tag: ITag
}

export const IconControl: React.FC<IIconControlProps> = (props) => {
    const { tag } = props;
    
    const [open, setOpen] = useState(false);
    const launcherRef = useRef<HTMLDivElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const updateIcon = (iconKey: string) => {
        tag.iconKey = iconKey;
        tag.icon = IconLibrary[iconKey];
    }

    return <>
        <div ref={launcherRef} onClick={handleOpen}>
            <IconButton>
                {tag.icon}
            </IconButton>
        </div>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={{
              bgcolor: 'background.paper',
              position: "absolute",
              top: (launcherRef.current?.getBoundingClientRect().top || 0) + (launcherRef.current?.clientHeight || 0),
              left: launcherRef.current?.getBoundingClientRect().left,
              width: 500,
              boxShadow: 24,
            }}>
                <div className="tagEditIconModal">
                {
                    Object.keys(IconLibrary).map((iconKey: string, index: number) => 
                        <IconButton key={index} onClick={() => updateIcon(iconKey)}>
                            {IconLibrary[iconKey]}
                        </IconButton>
                    )
                }
                </div>
            </Box>
      </Modal>
    </>;
}