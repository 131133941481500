import React, { useState, useCallback, useRef } from 'react';
import { TaskStatus} from "../Task";
import '../../styles/Tasks.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import OutlinedInput from '@mui/material/OutlinedInput';

import SettingsIcon from '@mui/icons-material/Settings';

import { ListItemText, Typography } from '@mui/material';

enum Filter {
    Created = "Created",
    Name = "Name",
    HabitType = "Habit Type",
    TaskStatus = "Task Status",
    Category = "Category"
}

interface ITaskListOptionsProps {
    setSortOrder: (filter: Filter) => void,
    visibleStatus: TaskStatus[],
    setVisibleStatus: (status: TaskStatus[]) => void,
}

export const TaskListOptions: React.FC<ITaskListOptionsProps> = (props) => {
    const {setSortOrder, visibleStatus, setVisibleStatus} = props;
    const [open, setOpen] = useState(false);
    const launchButtonRef = useRef<HTMLButtonElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const updateSort = useCallback((event: SelectChangeEvent<Filter>) => {
        let comparator = null;
        const filter = Object.keys(Filter).find(
                (key: string) =>
                Filter[key as keyof typeof Filter] === event.target.value) as Filter;
        setSortOrder(Filter[filter.toString() as keyof typeof Filter]);
    }, [setSortOrder]);

    const updateStatusUsability = useCallback((event: SelectChangeEvent<TaskStatus[]>) => {
        let updated: TaskStatus[];
        if (typeof event.target.value === 'string') {
            updated = [];
        }
        else {
            updated = event.target.value;
        }
        setVisibleStatus(updated);
    }, []) ;

    return <>
        <IconButton ref={launchButtonRef} onClick={handleOpen} sx={{ color: "white", margin: "5px" }}>
            <SettingsIcon />
        </IconButton>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={{
              position: "relative",
              top: (launchButtonRef.current?.getBoundingClientRect().top || 0) + (launchButtonRef.current?.clientHeight || 0),
              left: launchButtonRef.current?.getBoundingClientRect().left,
              width: 500,
              maxWidth: "90%",
              boxShadow: 24,
            }} className="taskListModal">
                <div>
                    <div>
                        <Typography variant='body1'>Sort order</Typography>
                    </div>
                    <div>
                        <FormControl sx={{width: "100%"}} >
                            <InputLabel>Sort order</InputLabel>
                            <Select
                                label="Sort order"
                                onChange={updateSort}
                            >
                            <MenuItem value={Filter.Category}>Category</MenuItem>
                            <MenuItem value={Filter.Created}>Created</MenuItem>
                            <MenuItem value={Filter.Name}>Name</MenuItem>
                            <MenuItem value={Filter.HabitType}>HabitType</MenuItem>
                            <MenuItem value={Filter.TaskStatus}>TaskStatus</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div>
                    <div>
                        <Typography variant='body1'>Status visibility</Typography>
                    </div>
                    <div>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel>Status visibility</InputLabel>
                            <Select
                                multiple
                                onChange={updateStatusUsability}
                                input={<OutlinedInput label="Status visibility" />}
                                value={visibleStatus}
                                renderValue={(selected: TaskStatus[]) => selected.map((status: TaskStatus) => status.toString()).join(", ")}
                            >
                            {Object.keys(TaskStatus)
                                .map((status: string, index: number) => (
                                    <MenuItem key={index} value={TaskStatus[status as keyof typeof TaskStatus]}>
                                    <Checkbox checked={visibleStatus.indexOf(TaskStatus[status as keyof typeof TaskStatus]) > -1} />
                                    <ListItemText primary={TaskStatus[status as keyof typeof TaskStatus].toString()} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </Box>
      </Modal>
    </>;
}