export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const dayOfWeekString = (date: Date): string => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return daysOfWeek[date.getDay()];
};

export const daysBetween = (early: Date, late: Date): number => {
  return (early.getTime() - late.getTime()) / (1000 * 3600 * 24);
}

export const monthStart = (date: Date): number => {
  if (!date) { date = new Date(); }
  else { date = new Date(date); }
  date.setDate(1);
  return date.getDay();
}

export const monthDays = (date: Date): number => {
    return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
}

export const compareDates = (d1: Date, d2: Date): number => {
    if (d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()) { return 0; }
    else {
        return d1 > d2 ? 1 : -1;
    }
}
