import React, { useEffect, useState, useCallback } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';

import { TaskDashboard, TaskDashboardManager } from "./TaskDashboard";
import TaskListView, { TaskList } from "./TaskList"
import { Task } from "./Task"
import { ColorScheme } from "./utility/ColorPicker"

import '../styles/Tasks.css';
import { ISubscribable } from './Subscription';
import { useCalendarState } from '@mui/x-date-pickers/internals';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 400;

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open'})<AppBarProps>
    (({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    height: `calc(100% - ${65}px)`,
    marginLeft: `-${drawerWidth}px`,
    marginTop: `65px`,
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface IPageLayoutFrame {
    data: Task[],
    setData: (updatedTasks: Task[]) => void,
    logout: () => void,
}

const PageLayoutFrame: React.FC<IPageLayoutFrame> = ({ data, setData, logout}) => {
    const [taskList, _setTaskList] = useState<TaskList>(new TaskList(data));

    const [cookies, setCookie] = useCookies<"sidebarOpen", { "sidebarOpen": boolean }>(["sidebarOpen"]);

    const [activeTasks, setActiveTasks] = useState<Task[]>([]);
    const [taskDashboard, _setTaskDashboard] = useState<TaskDashboardManager>(
      new TaskDashboardManager(activeTasks)
    );
    
    const updateActiveTasksCallback = useCallback((eventSrc: ISubscribable | null) => {
        setActiveTasks([...activeTasks]);
        localStorage.setItem("activeTasks", JSON.stringify(activeTasks.map((task: Task) => task.id)));
    }, [activeTasks, setActiveTasks]);

    console.log("APGELAYOUT")

    useEffect(() => {
      console.log("EFFECT")
      const loadedActiveTasks: Task[] = [];
      const loadedActiveTasksRaw = localStorage.getItem("activeTasks");
      if (loadedActiveTasksRaw) {
          JSON.parse(loadedActiveTasksRaw).forEach((taskId: string) => {
            const task = data.find((task: Task) => task.id === taskId);
            if (task) {
                loadedActiveTasks.push(task);
                taskDashboard.subscribeToTask(task);
            }
          });
      }
      setActiveTasks(loadedActiveTasks);
      
      taskDashboard.addSubscriptionCallback(
        updateActiveTasksCallback, "pageLayout"
      );

      return () => {
          taskDashboard.removeSubscriptionCallback("pageLayout");
      }
    }, [data, taskDashboard, setActiveTasks, taskDashboard]);

    const theme = useTheme();

    const handleDrawerOpen = () => {
        setCookie("sidebarOpen", true);
    };
    const handleDrawerClose = () => {
        setCookie("sidebarOpen", false);
    };

    return (
        <>
            <AppBar className="appTopNav" position="fixed" open={cookies.sidebarOpen}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(cookies.sidebarOpen && { display: 'none' }) }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h5" noWrap component="div">
                    HabitSelf
                  </Typography>
                  <div className="appActionButtons">
                    <IconButton onClick={logout}>
                        <LogoutIcon />
                    </IconButton>
                  </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                  width: drawerWidth,
                  maxWidth: "100%",
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    maxWidth: "100%",
                    boxSizing: 'border-box'
                  },
                }}
                variant="persistent"
                anchor="left"
                open={cookies.sidebarOpen}
              >
                <DrawerHeader className="appTopNav">
                    <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1}}>
                        Task List
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <TaskListView taskList={taskList} 
                    taskListUpdate={setData}
                    taskDashboard={taskDashboard} 
                    activeTasks={activeTasks}
                    setActiveTasks={setActiveTasks} />
            </Drawer>
            <Main open={cookies.sidebarOpen}>
                <div className="mainHeader">
                </div>
                <div className="mainContent">
                    <TaskDashboard manager={taskDashboard} activeTasks={activeTasks} setActiveTasks={setActiveTasks}/>
                </div>
            </Main>
        </>
    );
};

export default PageLayoutFrame;
