/**
 *  Imports
 **/
import React, { useCallback, useState } from 'react';

import { ISubscriberCallback, ISubscribable } from "./Subscription";

import ColorPicker from "./utility/ColorPicker";

/**
 *      Material UI
 **/
import EditIcon from '@mui/icons-material/Edit';
import TodayIcon from '@mui/icons-material/Today';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import { daysBetween } from "./utility/Dates";
import { StatusControl } from "./TaskCard/StatusControl";
import { TaskEditForm, TaskEditModal } from "./TaskCard/TaskEditModal";
import { ITag } from "./TaskCard/TaskCategory";
import {TaskEvent, TaskEventEditor} from "./TaskEvent";
import { saveTask, deleteDBEvent } from "./Loader/Persist";
import '../styles/Tasks.css';
import '../styles/App.css';

/**
 *      Constants
 **/

export enum TaskStatus {
    NotStarted = 'Not Started',
    InProgress = 'In Progress',
    Completed = 'Completed',
    Abandoned = 'Abandoned'
}

// i times every n days, i times every week, i times every month
export enum TaskFrequency {
    Day = 'Days',
    Week = 'Weeks',
    Month = 'Months'
}

export enum HabitType {
    Good = 'Good',
    Bad = 'Bad',
    Neutral = 'Neutral'
}

export enum TaskMeasurement {
    Binary = 'Binary',
    Count = 'Count',
}

export interface TaskInterval {
    quantity: number,
    unit: TaskFrequency
}

/**
 *      Data Structures
 **/

export interface ITaskMetadata {
    id?: string;
    title: string;
    description?: string;
    status: TaskStatus;
    created: Date;
    repetitions: number;
    interval: TaskInterval;
    measurement: TaskMeasurement;
    habitType: HabitType;
    category?: ITag;
    userID?: string;
}

export class Task implements ISubscribable, ITaskMetadata {
    // Properties of the Task
    private _id?: string;
    private _tempId?: string;
    private _title: string;
    private _description?: string;
    private _status: TaskStatus;
    private _created: Date;
    private _repetitions: number;
    private _interval: TaskInterval;
    private _measurement: TaskMeasurement;
    private _habitType: HabitType;
    private _category?: ITag;
    private _taskHistory: TaskEvent[];
    private _userID: string;

    subscriberCallbacks: Map<string, ISubscriberCallback>;

    // Constructor to initialize the Task
    constructor(base?: ITaskMetadata) {
        this._id = base?.id;
        if (this._id === null) { this._tempId = (new Date).getTime().toString(); }

        this._title = base?.title || "";
        this._description = base?.description;
        this._status = base?.status || TaskStatus.NotStarted;
        this._created = base?.created || new Date();
        this._repetitions = base?.repetitions || 1;
        this._interval = {
            quantity: base?.interval.quantity || 1,
            unit: base?.interval.unit || TaskFrequency.Day
        } as TaskInterval
        this._measurement = base?.measurement || TaskMeasurement.Binary;
        this._habitType = base?.habitType || HabitType.Good;
        this._taskHistory = [];
        this._category = base?.category;
        this._userID = base?.userID || "";

        this.subscriberCallbacks = new Map();
    }

    /**
    *       Accessors / mutators
    **/
        get id(): string { return (this._id || "") + "." + (this._tempId || ""); }

        get title(): string { return this._title; }
        set title(title: string) { this._title = title; }

        get description(): string { return this._description || ""; }
        set description(description: string) { this._description = description; }

        get status(): TaskStatus { return this._status; }
        set status(status: TaskStatus) { this._status = status; }

        get created(): Date { return this._created; }
        set created(created: Date) { this._created = created; }

        get repetitions(): number { return this._repetitions; }
        set repetitions(repetitions: number) { this._repetitions = repetitions; }

        get interval(): TaskInterval { return this._interval; }
        set interval(interval: TaskInterval) { this._interval = interval; }

        get measurement(): TaskMeasurement { return this._measurement; }
        set measurement(measurement: TaskMeasurement) { this._measurement = measurement; }

        get habitType(): HabitType { return this._habitType; }
        set habitType(habitType: HabitType) { this._habitType = habitType; }

        get history(): TaskEvent[] { return this._taskHistory; }

        get category(): ITag | undefined { return this._category; }
        set category(category: ITag | undefined) { this._category = category; }

        get userID(): string { return this._userID; }
        set userID(userID: string) { this._userID = userID; }

    /**
     * Subscribable implementation
     **/

        public notify(): void {
            this.subscriberCallbacks.forEach(
                (callback: ISubscriberCallback, _key: string) => {
                    callback(this)}
            );
        }

        public subscribe(callback: ISubscriberCallback, subscriptionKey: string) {
            this.subscriberCallbacks.set(subscriptionKey, callback);
        }

        public unsubscribe(subscriptionKey: string) {
            this.subscriberCallbacks.delete(subscriptionKey);
        }

    /**
     * Class methods
     **/

        public save() {
            saveTask(this);
            console.log("TASKSAVE")
        }

        public addEvent(date?: Date): TaskEvent {
            const newEvent = new TaskEvent(this, date);
            this.history.push(newEvent);
            this.sortHistory();
            this.notify();
            newEvent.save();
            return newEvent;
        }

        public deleteEvent(taskEvent: TaskEvent): void {
            const index = this.history.indexOf(taskEvent);
            if (index >= 0) {
                deleteDBEvent(this.history[index]);
                this.history.splice(index, 1);
            }

            this.notify();
        }

        public sortHistory(): void {
            this.history.sort((t1: TaskEvent, t2: TaskEvent) => t1.date.getTime() - t2.date.getTime());
        }

        public getStreaks(queryStart: Date, queryEnd: Date = new Date()): Date[] {
            queryStart.setHours(0, 0, 0, 0);
            queryEnd.setHours(0, 0, 0, 0);

            const dayRange = this.interval.quantity;
            const streakDays: Date[] = [];

            for (let i=0; i < this.history.length; i++) {
                if (daysBetween(queryStart, this.history[i].date) >= dayRange
                    || i + 1 < this.repetitions
                    || daysBetween(this.history[i].date, this.history[i - this.repetitions + 1].date) >= dayRange) {
                        continue;
                }
                else {
                    const diff = daysBetween(this.history[i].date, this.history[i - this.repetitions + 1].date);
                    for (let j=0; j < dayRange - diff; j++) {
                        const streakDay = new Date()
                        streakDay.setDate(this.history[i].date.getDate() + j);
                        streakDay.setHours(0, 0, 0, 0);
                        if (streakDays.length == 0 || streakDay.getDate() > streakDays[streakDays.length - 1].getDate()) {
                            streakDays.push(streakDay);
                        }
                    }
                }
            }

            return streakDays;
        }
}


/**
 *      Display
 **/

interface ITaskCardProps {
    task: Task,
    isActive: boolean,
    initialState?: boolean,
    onClick: () => void,
    onDelete: (task: Task) => void
}

const TaskCard: React.FC<ITaskCardProps> = (props: ITaskCardProps) => {
    const {task, isActive, onClick, initialState, onDelete } = props;

    const [taskDetails, setTaskDetails] = useState<ITaskMetadata>({
        title: task.title,
        description: task.description,
        status: task.status,
        created: task.created,
        repetitions: task.repetitions,
        interval: task.interval,
        measurement: task.measurement,
        habitType: task.habitType,
        category: task.category,
    });

    // Event editor
    const [openEvent, setOpenEvent] = useState<TaskEvent | null>(null);

    // Field edit
    const [isEditingTask, setIsEditingTask] = useState(initialState || false);

    const colorTheme = isActive ? ColorPicker.getColor(task) : "#ccc";
    const secondaryColor = isActive ? ColorPicker.getNeutralColor(task!) : "black";

    const newEventCallback = useCallback(() => {
        const event = task.addEvent();
        setOpenEvent(event);
    }, [task]);

    const setTaskStatus = useCallback((status: TaskStatus) => {
        task.status = status;
        task.save();
        setTaskDetails({...taskDetails, status: task.status});
    }, [task]);

    const toggleEditMode = () => { setIsEditingTask(!isEditingTask) };
    return (
        <Card className={`taskCard ${ isActive && "activeTaskCard"}`}>
            { isEditingTask ?
            <TaskEditForm task={task} taskDetails={taskDetails} setTaskDetails={setTaskDetails}
            onSubmit={toggleEditMode} onAbort={toggleEditMode} />
            :
            <>
                <CardContent className={`taskCardContents ${taskDetails.habitType === HabitType.Good ? "goodHabit" : "badHabit"}`} sx={{padding:0}}>
                    <Typography className="cardHeader"  onClick={onClick} variant="h6" component="div"
                        sx={{ backgroundColor: colorTheme, cursor: "pointer", color: secondaryColor }}>
                        <Checkbox className="checkbox" checked={isActive} sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: secondaryColor } }}/>
                        {taskDetails.title}
                        <div className="categoryIcon">
                            {   taskDetails.category &&
                                <Tooltip title={taskDetails.category?.name}>
                                    {taskDetails.category.icon}
                                </Tooltip>
                            }
                        </div>
                    </Typography>
                    {
                        taskDetails.description && <Typography variant="body1" sx={{ fontSize: 18 }}>
                            <span> {taskDetails.description} </span>
                        </Typography>
                    }
                    {/* <Typography variant="body2" sx={{ fontSize: 15 }}>
                        <strong>{taskDetails.repetitions} / {taskDetails.interval.quantity} {taskDetails.interval.unit}</strong>
                    </Typography> */}
                    <div>
                    <StatusControl status={taskDetails.status} setStatusCallback={setTaskStatus} />
                    </div>
                    <Typography variant="body2" className="taskContentFooter" sx={{ textAlign: "right", fontSize: 12 }}>
                        <strong>Created:</strong> {taskDetails.created.toDateString()}
                    </Typography>
                </CardContent>
                <CardActions className="taskCardActions">
                    <div>
                        <TaskEditModal task={task} taskDetails={taskDetails} setTaskDetails={setTaskDetails}>
                            <Tooltip title="Edit in new winodw">
                                <Button startIcon={<LaunchIcon />} />
                            </Tooltip>
                        </TaskEditModal>
                    </div>
                    <div>
                    <Tooltip title="Edit inline">
                        <Button className="taskCardActionsButton" startIcon={<EditIcon />} onClick={toggleEditMode} />
                    </Tooltip>
                    </div>
                    <div>
                    <Tooltip title="New event">
                        <Button className="taskCardActionsButton" startIcon={<TodayIcon />} onClick={newEventCallback} />
                    </Tooltip>
                    </div>
                    <div>
                    <DeleteTaskButton task={task} deleteCallback={() => onDelete(task)}/>
                    </div>
                </CardActions>
            </>
            }
            { openEvent && <TaskEventEditor taskEvent={openEvent}
                                open={openEvent !== null}
                                handleClose={() => setOpenEvent(null)} /> }
        </Card>
    );
}

export interface IDeleteConfirmationDialogProps {
    task: Task,
    open: boolean;
    onOK: () => void;
    onClose: () => void;
}

const DeleteConfirmationDialog: React.FC<IDeleteConfirmationDialogProps> = (props) => {
  const { task, onOK, onClose, open } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onOK();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>Delete task: {task.title}</DialogTitle>
      <DialogContent dividers>
        This task will be permanently deleted. Abandon if you want to keep the history of the task.
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}> Cancel </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

const DeleteTaskButton: React.FC<{ task: Task, deleteCallback: () => void }> = ({task, deleteCallback}) => {
  const [open, setOpen] = useState(false);

  const openConfirmDialog = () => {
    setOpen(true);
  };

  const handleOK = () => {
    deleteCallback();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Tooltip title="Delete">
        <Button className="taskCardActionsButton" startIcon={<DeleteIcon />} onClick={openConfirmDialog} />
    </Tooltip>
        <DeleteConfirmationDialog task={task} open={open} onOK={handleOK} onClose={handleClose}
        />
    </>;
}

export default TaskCard;
