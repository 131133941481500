import React, { ReactNode, useState, useCallback, useEffect } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import IconButton from '@mui/material/IconButton';

import {CalendarMonth, CalendarWeek} from "./Month";
import {Task} from "../Task";

import '../../styles/Calendar.css';
import '../../styles/Dashboard.css';
import { CalendarOptions, CalendarRange } from './CalendarOptions';
import { dayOfWeekString, daysOfWeek, monthDays } from '../utility/Dates';

interface ICalendarControlProps {
    tasks: Task[]
}

export const CalendarControl: React.FC<ICalendarControlProps> = (props: ICalendarControlProps) => {
    const { tasks } = props;

    const [calendarRange, setCalendarRange] = useState<CalendarRange>(CalendarRange.Month);

    const date = new Date();
    const [year, setYear] = useState(date.getFullYear());
    const [month, setMonth] = useState(date.getMonth());
    
    const tmp = dayOfWeekString(date);
    const [weekStartDate, setWeekStartDate] = useState(date.getDate() - daysOfWeek.indexOf(tmp));

    const nextMonth = useCallback(() => {
        if (month < 12) { setMonth(month + 1); }
        else { setMonth(1); setYear(year + 1); }
        setWeekStartDate(1);
    }, [month, setMonth, year, setYear, setWeekStartDate]);

    const prevMonth = useCallback(() => {
        if (month > 1) { setMonth(month - 1); }
        else { 
            setMonth(12); 
            setYear(year - 1); 
            setWeekStartDate(1);
        }
    }, [month, setMonth, year, setYear, setWeekStartDate]);

    const nextWeek = useCallback(() => {
        const newDate = new Date(year, month, weekStartDate);
        newDate.setDate(weekStartDate + 7)
        setWeekStartDate(newDate.getDate());
        if (newDate.getMonth() !== month) {
            setMonth(newDate.getMonth());
            setYear(newDate.getFullYear());
        }
    }, [month, setMonth, year, setYear, weekStartDate, setWeekStartDate]);

    const prevWeek = useCallback(() => {
        const newDate = new Date(year, month, weekStartDate);
        newDate.setDate(weekStartDate - 7)
        setWeekStartDate(newDate.getDate());
        if (newDate.getMonth() !== month) {
            setMonth(newDate.getMonth());
            setYear(newDate.getFullYear());
        }
    }, [month, setMonth, year, setYear, weekStartDate, setWeekStartDate]);

    return <div>
        <div className='calendarControlToolbar'>
            <div className='calendarControlMonthToggle'>
                <IconButton onClick={calendarRange === CalendarRange.Month ? prevMonth: prevWeek}>
                <NavigateBeforeIcon />
                </IconButton>
                    { (new Date(year, month, 1)).toLocaleDateString(
                        'en-US',
                        { month: 'long', year: 'numeric'}
                    ) }
                <IconButton onClick={calendarRange === CalendarRange.Month ? nextMonth: nextWeek}>
                <NavigateNextIcon />
                </IconButton>
            </div>
            <CalendarOptions calendarRange={calendarRange} setCalendarRange={setCalendarRange}/>
        </div>
        <div className="calendarMonth">
            {
                Array.from({ length: 7}, (_, i) => i).map((day: number) =>
                    <div className="calendarMonthWeekdayLabel" key={day}>
                        { daysOfWeek[day] }
                    </div>)
            }
        </div>
        {
            calendarRange === CalendarRange.Week ? 
            <CalendarWeek 
                month={month} year={year} tasks={tasks}
                offset={weekStartDate <= 0 ? Math.abs(weekStartDate) + 1 : 0}
                startDate={weekStartDate}/> : 
            <CalendarMonth month={month} year={year} tasks={tasks} />
        }
    </div>;
}
