import { Task } from "../Task";

const colors = [
    "#D4CDAB",
    "#8E9B90",
    "#93C0A4",
    "#B6C4A2",
    "#DCE2BD",
    "#A3D9FF",
    "#13262F",
    "#B75D69",
    "#774C60",
    "#3E92CC",
    "#272727",
    "#49A078",
    "#F78E69",
    "#E0BAD7",
    "#FFCFD2",
    "#CCFBFE",
    "#8B635C",
    "#60594D",
    "#FFB563"
]

export class ColorPicker {
    private colorMode: ColorScheme;
    private palettes: Map<ColorScheme, ColorSchemePalette>;

    constructor() {
        this.colorMode = ColorScheme.TaskUnique;

        this.palettes = new Map();
        Object.keys(ColorScheme).forEach((scheme: string, ind: number) => {
            this.palettes.set(ColorScheme[scheme as keyof typeof ColorScheme], new ColorSchemePalette());
        })
    }

    public getPalette(): ColorSchemePalette {
        return this.palettes.get(this.colorMode)!;
    }

    public getColor(task: Task): string {
        return this.getPalette().getColor(task.id);
    }

    public releaseKey(key: string) {
        this.getPalette().releaseKey(key);
    }

    public getNeutralColor(task: Task): string {
        return neutralComplement(this.getColor(task));
    }
}

export class ColorSchemePalette{
    private available: Set<string>;
    private unavailable: Set<String>;
    private assignments: Map<string, string>;
    private colorMode: ColorScheme;

    constructor() {
        this.available = new Set(colors);
        this.unavailable = new Set();
        this.assignments = new Map<string, string>();
        this.colorMode = ColorScheme.TaskUnique;
    }

    private assignColor(): string {
        if (this.available.size === 0) { return "FFF"; }
        const available = Array.from(this.available);
        const randomIndex = Math.floor(Math.random() * available.length);
        this.available.delete(available[randomIndex]);
        this.unavailable.add(available[randomIndex]);
        return available[randomIndex];
    }

    public getColor(key: string): string {
        let assignedColor = this.assignments.get(key);
        if (assignedColor === undefined) {
            assignedColor = this.assignColor();
            this.assignments.set(key, assignedColor);
        }
        return assignedColor;
    }

    public releaseKey(key: string) {
        const color = this.assignments.get(key);
        if (!color) {
            return;
        }
        this.releaseColor(color);
        this.assignments.delete(key);
    }

    private releaseColor(color: string) {
        if (!this.unavailable.has(color)) {
            return;
        }
        this.unavailable.delete(color);
        this.available.add(color);
    }
}

export enum ColorScheme {
    TaskUnique = "By Task",
    CategoryUnique = "By Category",
    HabitTypeUnique = "By Habit Type",
}

const instance = new ColorPicker();
Object.freeze(instance);
export default instance; 

/**
 * Helper
 */

// Mark Ransom from StackOverflow
// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export function neutralComplement(background: string | undefined) {
    if (!background) { return "#fff" };
    const red = parseInt(background.substring(1, 3), 16);
    const green = parseInt(background.substring(3, 5), 16);
    const blue = parseInt(background.substring(5, 7), 16);
    if ((red*0.299 + green*0.587 + blue*0.114) > 186) {
        return "#000"
    }
    else {
        return "#fff"
    }
}

export function isWhite(color: string) {
    return color.toLowerCase() === "#fff" || color.toLowerCase() === "#ffffff" || color.toLowerCase() === "white";
}