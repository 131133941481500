import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';
import { Task } from '../Task';

import * as d3 from "d3";
import { compareDates } from '../utility/Dates';

export const FrequencyGraph = (props) => {
    const { task } = props;

    //console.log(task.history);
    // Chart visual
    const width = 640;
    const height = 200;
    const marginTop = 20;
    const marginRight = 20;
    const marginBottom = 60;
    const marginLeft = 40;

    console.log("RENDER GRAPH");

    // Chart data
    const data = [];
    let weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - weekStart.getDay());
    const refWeek = new Date(weekStart);

    for (let i = task.history.length - 1; i >= 0; i--) {
        while (task.history[i].date < weekStart && data.length < 15) {
            data.push(0);
            weekStart.setDate(weekStart.getDate() - 7);
        }
        if (data.length >= 15) {
          break;
        }
        data[data.length - 1]++;
    }
    if (data.length === 0) {
      data.push(0);
    }
    data.reverse();
    // console.log(data);

    const gx = useRef(null);
    const gy = useRef(null);

    const x = d3.scaleLinear([0, data.length - 1], [marginLeft, width - marginRight]);
    const y = d3.scaleLinear(d3.extent(data), [height - marginBottom, marginTop]);
    const yMax = data.length === 0 ? 7 : Math.max(1, d3.max(data) + 1);
    const line = d3.line((d, i) => x(i), y);
    
    useEffect(() => void d3.select(gx.current)
        .call(d3.axisBottom(x).tickFormat((d) => {
            const startDate = new Date(refWeek)
            startDate.setDate(refWeek.getDate() - (15 - d) * 7);
            return startDate.toLocaleDateString();
        }))
        .selectAll("text")  
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)"), [gx, x]);
    
    useEffect(() => void d3.select(gy.current)
        .call(d3.axisLeft(y).tickValues( d3.range(yMax) ))
        .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1)), [gy, y]);
    
    return (
      <svg width={width} height={height}>
        {/* Axis X */}
        <g ref={gx} transform={`translate(0,${height - marginBottom})`} />
        {/* Axis Y */}
        <g ref={gy} transform={`translate(${marginLeft},0)`} />
        {/* Line */}
        <path fill="none" stroke="currentColor" strokeWidth="1.5" d={line(data)} />
        {/* Points */}
        <g fill="white" stroke="currentColor" strokeWidth="1.5">
          {data.map((d, i) => (<circle key={i} cx={x(i)} cy={y(d)} r="2.5" />))}
        </g>
      </svg>
    );
}