import React, { ReactNode, useState, useCallback, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';

import {Task} from "../Task"
import {TaskEvent, TaskEventEditor} from "../TaskEvent";

import ColorPicker from "../utility/ColorPicker";

import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';

import '../../styles/Calendar.css';
import '../../styles/Dashboard.css';
import "../../styles/App.css";

interface IEventLogProps {
    logStart: Date,
    logEnd: Date,
    eventsByTask: Map<Task, TaskEvent[]>
}

export const EventLog: React.FC<IEventLogProps> = (props: IEventLogProps) => {
    const { eventsByTask, logStart, logEnd } = props;

    return <>
        <div style={{ border: "1px solid black" }}>
            { eventsByTask.size === 0 && 
                <Typography variant='body1'>
                    There are no active tasks. 
                    Open the task sidebar to create a task and check it for the task to show
                    up in the calendar.
                </Typography>
            }
            {
                Array.from(eventsByTask.keys()).map(
                    (task: Task, index: number) => 
                        <EventLogTaskSimple key={index} task={task} {...props} />
                )
            }
        </div>
    </>;
}

interface IEventLogTaskProps extends IEventLogProps {
    task: Task,
}

export const EventLogTaskSimple: React.FC<IEventLogTaskProps> = (props: IEventLogTaskProps) => {
    var { eventsByTask, task, logStart } = props;
    
    const events = eventsByTask.get(task) || [];
    
    const color = ColorPicker.getColor(task);
    const neutralColor = ColorPicker.getNeutralColor(task);

    return <div key={task.title} className="eventLogTaskSimple" style={{ borderColor: color }}>
        <div className="eventLogTaskSimpleHeader">
            <IconButton onClick={() => task.addEvent(logStart)}>
                <AddIcon/>
            </IconButton>
            <Typography variant='body1' sx={{fontSize: "1.25rem"}}>
                {task.title}
            </Typography>
        </div>
        {
            events?.map((event: TaskEvent, index: number) =>
                <EventLogEntry event={event} key={index}/>
        )}
    </div>;
}


export const EventLogTaskCard: React.FC<IEventLogTaskProps> = (props: IEventLogTaskProps) => {
    var { eventsByTask, task, logStart } = props;
    
    const events = eventsByTask.get(task) || [];

    const color = ColorPicker.getColor(task);
    const neutralColor = ColorPicker.getNeutralColor(task);

    return <>
        <div className="EventLogTask">
            <Card sx={{ minWidth: 275 }}>
                <CardActions sx={{backgroundColor: color, color: neutralColor, paddingTop: 0, paddingBottom: 0}}>
                    <Typography className="EventLogTaskTitle" variant="h5">
                        {task.title}
                    </Typography>
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </CardActions>
                <CardContent sx={{p: 1.5}}>
                    <div className="eventLogHistoryQuickView">
                        <div className="row">
                            <div className="rowLeft">
                                <IconButton>
                                    <AddIcon sx={{color: color}}/>
                                </IconButton>
                                <div className="eventLogCompletionMark" style={{
                                    backgroundColor: color,
                                    border: `5px solid ${color}`
                                }}></div>
                            </div>
                            <div>
                                {
                                    events?.map((_: any) => <div className="eventLogCompletionMark" style={{
                                        backgroundColor: color,
                                        border: `5px solid ${color}`
                                    }}> </div>)
                                }
                            </div>
                            <div className="rowRight">
                                <IconButton>
                                    <AddIcon sx={{color: color}}/>
                                </IconButton>
                                <div className="eventLogToDoMark" style={{
                                    border: `5px solid ${color}`
                                }}></div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            {
                events?.map(
                    (event: TaskEvent, index: number) => 
                        <EventLogEntry key={index} event={event} />
                )
            }
        </div>
    </>;
}

interface IEventLogEntryProps {
    event: TaskEvent
}

export const EventLogEntry: React.FC<IEventLogEntryProps> = (props: IEventLogEntryProps) => {
    const { event } = props;

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <div>
        <>
            <div className="eventLogEntry" onClick={handleOpen}>
                <IconButton size="small"><EditIcon fontSize='small' /> </IconButton>
                <span className="eventLogEntryTitle">{ event.toString() }</span>
                <Typography variant="body1" className="eventLogEntryDesc">
                    {event.comment}
                </Typography>
            </div>
            <TaskEventEditor taskEvent={event} open={open} handleClose={handleClose} />
        </>
    </div>;
}