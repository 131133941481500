import React, { useState, useCallback } from 'react';
import {TryLogin, TryRegister} from "../Loader/Login";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { GUEST_ID } from '../Loader/Persist';

import "../../styles/Login.css";

interface ILoginModalProps {
    setUser: (userID: string) => void
}

export const LoginModal: React.FC<ILoginModalProps> = (props) => {
    const { setUser } = props;
  const [activeTab, setActiveTab] = React.useState('Login');

    const changeTabs = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    return (<Modal open={true}>
        <Box className="modalBox loginModal" sx={{
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
            <Tabs onChange={changeTabs} value={activeTab}>
                <Tab label="Login" value="Login" />
                <Tab label="Register" value="Register" />
            </Tabs>
            <div
              role="tabpanel"
              hidden={activeTab !== "Login"}
            >
                <LoginForm setUser={setUser}/>
            </div>
            <div
              role="tabpanel"
              hidden={activeTab !== "Register"}
            >
                <RegistrationForm />
            </div>
        </Box>
      </Modal>
  );
}

export const LoginForm: React.FC<{setUser: (userID: string) => void}> = ({setUser}) => {
    const [nameLogin, setNameLogin] = useState("");
    const [passwordLogin, setPasswordLogin] = useState("");
    const [showLoginError, setShowLoginError] = useState(false);

    const loginCallback = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const userID = await TryLogin({ username: nameLogin, password: passwordLogin });
        setShowLoginError(userID.length === 0);
        if ( userID ) { setUser(userID); }
    }, [nameLogin, passwordLogin]);

    const onGuestLogin = useCallback(() => {
        setUser(GUEST_ID);
    }, []);

    const onLoginChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNameLogin(event.target.value);
    }, [nameLogin, setNameLogin]);
    const onPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordLogin(event.target.value);
    }, [passwordLogin, setPasswordLogin]);

    return <>
        <form onSubmit={loginCallback}>
            <CardContent className="loginForm">
                <Alert severity="info" className="loginModalBanner">
                    This site is primarily for personal use and on the cheapest possible settings I can get away with on Heroku.
                    As a result, account creation is limited. Use the guest option to explore, or reach out to the site owner to create an account.</Alert>
                { showLoginError &&
                    <Alert severity="error" className="loginModalBanner">Login failed. Double check the username and password and try again.</Alert>
                }
                <TextField
                    required
                    label="Username"
                    sx={{width: "100%"}}
                    onChange={onLoginChange}
                />
                <TextField
                    required
                    label="Password"
                    type="password"
                    sx={{width: "100%"}}
                    onChange={onPasswordChange}
                />
            </CardContent>
            <CardActions>
                <Button size="large" type="submit" fullWidth startIcon={<LoginIcon />}>
                    Login
                </Button>
                <Button size="large" onClick={onGuestLogin} fullWidth startIcon={<PersonOutlineIcon />}>
                    Guest
                </Button>
            </CardActions>
        </form>
    </>;
}

export const RegistrationForm: React.FC = () => {
    const [nameLogin, setNameLogin] = useState("");
    const [passwordLogin, setPasswordLogin] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [showRegistrationSuccess, setShowRegistrationSuccess] = useState(false);
    const [showRegistrationError, setShowRegistrationError] = useState(false);

    const registerCallback = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const success = await TryRegister({ username: nameLogin, password: passwordLogin });
        setShowRegistrationError(!success);
        setShowRegistrationSuccess(success);
    }, [nameLogin, passwordLogin]);

    const onLoginChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNameLogin(event.target.value);
    }, [nameLogin, setNameLogin]);
    const onPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordLogin(event.target.value);
    }, [passwordLogin, setPasswordLogin]);
    const onConfirmChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirm(event.target.value);
    }, [passwordConfirm, setPasswordConfirm]);

    return <>
        <form onSubmit={registerCallback}>
            <Alert severity='info'>This site is primarily for personal use and on the cheapest possible settings I can get away with on Heroku.
                As a result, account creation is limited. Use the guest option to explore, or reach out to the site owner to create an account.</Alert>
            { showRegistrationSuccess &&
                <Alert severity="success" className="loginModalBanner">Registration succeeded. Feel free to login.</Alert>
            }
            { showRegistrationError &&
                <Alert severity="error" className="loginModalBanner">Registration failed. Account creation is currently limited. Reach out to the site owner to proceed.</Alert>
            }
            <CardContent className="loginForm">
                <TextField
                    required
                    label="Username"
                    sx={{width: "100%"}}
                    onChange={onLoginChange}
                />
                <TextField
                    required
                    label="Password"
                    type="password"
                    sx={{width: "100%"}}
                    onChange={onPasswordChange}
                />
                <TextField
                    required
                    label="Confirm Password"
                    type="password"
                    sx={{width: "100%"}}
                    onChange={onConfirmChange}
                />
            </CardContent>
            <CardActions>
                <Button size="large" type="submit" fullWidth startIcon={<PersonAddIcon />} >
                    Register
                </Button>
            </CardActions>
        </form>
    </>;
}
