import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import { TaskStatus} from "../Task";
import '../../styles/Tasks.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';

import SettingsIcon from '@mui/icons-material/Settings';

import { FormControlLabel, FormLabel, ListItemText, Radio, RadioGroup, Typography } from '@mui/material';

export enum CalendarRange {
    Week = "Week",
    Month = "Month"
}

interface ICalendarOptionsProps {
    calendarRange: CalendarRange,
    setCalendarRange: (val: CalendarRange) => void
}

export const CalendarOptions: React.FC<ICalendarOptionsProps> = (props) => {
    const {calendarRange, setCalendarRange} = props;
    const [open, setOpen] = useState(false);
    const launchButtonRef = useRef<HTMLButtonElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const updateCalendarRange = useCallback((event: React.ChangeEvent, value: string) => {
        setCalendarRange(CalendarRange[value as keyof typeof CalendarRange]);
    }, [setCalendarRange]);

    return <>
        <IconButton ref={launchButtonRef} onClick={handleOpen} sx={{ color: "white", margin: "5px" }}>
            <SettingsIcon />
        </IconButton>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={{
              position: "relative",
              top: (launchButtonRef.current?.getBoundingClientRect().top || 0) + (launchButtonRef.current?.clientHeight || 0),
              left: launchButtonRef.current?.getBoundingClientRect().left,
              width: 300,
              boxShadow: 24,
            }} className="calendarOptionsModal">
                <div>
                    <FormControl>
                        <FormLabel>Calendar Range</FormLabel>
                        <RadioGroup row value={calendarRange} onChange={updateCalendarRange}>
                            {Object.keys(CalendarRange).map((key) =>
                                <FormControlLabel label={CalendarRange[key as keyof typeof CalendarRange]} 
                                    value={CalendarRange[key as keyof typeof CalendarRange]} 
                                    control={<Radio />} 
                                    key={key} />
                            )}
                        </RadioGroup>
                    </FormControl>
                </div>
            </Box>
      </Modal>
    </>;
}