import {
    Assignment as TaskIcon,
    Work as WorkIcon,
    FitnessCenter as FitnessIcon,
    ShoppingCart as ShoppingIcon,
    Event as EventIcon,
    School as StudyIcon,
    Home as HomeIcon,
    Restaurant as FoodIcon,
    LocalHospital as HealthIcon,
    DirectionsCar as TravelIcon,
    MusicNote as MusicIcon,
    Brush as ArtIcon,
    SportsSoccer as SportsIcon,
    LocalMovies as MoviesIcon,
    Book as ReadingIcon,
    Build as MaintenanceIcon,
    Flight as FlightIcon,
    Pets as PetCareIcon,
    NaturePeople as OutdoorIcon,
    Spa as RelaxationIcon
} from '@mui/icons-material';

type IconLibrary = {
    [key: string]: React.ReactElement;
};

const IconLibrary: IconLibrary = {
    Task: <TaskIcon />,
    Work: <WorkIcon />,
    Fitness: <FitnessIcon />,
    Shopping: <ShoppingIcon />,
    Event: <EventIcon />,
    Study: <StudyIcon />,
    Home: <HomeIcon />,
    Food: <FoodIcon />,
    Health: <HealthIcon />,
    Travel: <TravelIcon />,
    Music: <MusicIcon />,
    Art: <ArtIcon />,
    Sports: <SportsIcon />,
    Movies: <MoviesIcon />,
    Reading: <ReadingIcon />,
    Maintenance: <MaintenanceIcon />,
    Flight: <FlightIcon />,
    PetCare: <PetCareIcon />,
    Outdoor: <OutdoorIcon />,
    Relaxation: <RelaxationIcon />
};

export default IconLibrary;