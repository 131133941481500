
import {Task, TaskFrequency, HabitType, ITaskMetadata, TaskStatus} from "../Task"
import {TaskEvent, ITaskEvent} from "../TaskEvent";
import {ITag, TagList} from "../TaskCard/TaskCategory";

export const GUEST_ID = "GUEST_ID";

interface TaskJson extends ITaskMetadata, ITaskEvent {
    id: string,
    interval_quantity: number,
    interval_frequency: TaskFrequency,
    category_name: string,
    habit_type: HabitType
    created: Date,
    user_id: string,
    event_id?: string
}

// Expects - a list of taskevents
export function load(json: TaskJson[]) {
    // Build the tasks
    const tasks: Task[] = [];
    const tasksById = new Map<string, Task>();

    json.forEach((taskJson: TaskJson) => {
        let task = null;
        if (!tasksById.has(taskJson.id)) {
            taskJson.interval = {
                quantity: taskJson.interval_quantity,
                unit: taskJson.interval_frequency
            }
            taskJson.habitType = taskJson.habit_type;
            taskJson.userID = taskJson.user_id;
            taskJson.created = new Date(taskJson.created);
            TagList.forEach((cat: ITag) => {
                if (cat.name === taskJson.category_name) {
                    taskJson.category = cat;
                }
            });
            task = new Task(taskJson);
            tasks.push(task);
            tasksById.set(taskJson.id, task);
        } else {
            task = tasksById.get(taskJson.id);
        }

        if (taskJson.date) {
            task!.history.push(
                new TaskEvent(
                    task!,
                    new Date(taskJson.date),
                    taskJson.quantity,
                    taskJson.comment,
                    taskJson.completed,
                    taskJson.event_id
                )
            );
        }
    });

    tasks.forEach((task: Task) => {
        task.sortHistory();
    })
    // build the tags
    return tasks;
};

// Expects - a list of taskevents
export function saveTask(task: Task) {
    if (task.userID === GUEST_ID) { return; }
    fetch(task.id ? `/api/task/${task.id}` : "/api/task", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: task.title,
        description: task.description,
        status: task.status,
        created: task.created,
        repetitions: task.repetitions,
        interval_quantity: task.interval.quantity,
        interval_unit: task.interval.unit,
        measurement: task.measurement,
        habit_type: task.habitType,
        category_name: task.category?.name,
        user_id: task.userID
      })
    });
};

export function deleteTask(task: Task) {
    if (task.userID === GUEST_ID) { return; }

    fetch(task.id ? `/api/task/${task.id}` : "/api/task", {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
}

export async function saveEvent(event: TaskEvent): Promise<string> {
    if (event.task.userID === GUEST_ID) { return crypto.randomUUID(); }

    const newEvent = await fetch(event.id ? `/api/task/${event.task.id}/event/${event.id}` : `/api/task/${event.task.id}/event`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        date: event.date,
        quantity: event.quantity,
        comment: event.comment,
        completed: event.completed
      })
    }).then((res) => res.json());
    return newEvent.id;
};

export function deleteDBEvent(event: TaskEvent) {
    if (event.task.userID === GUEST_ID) { return; }

    if (event.id) {
        fetch(`/api/task/${event.task.id}/event/${event.id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
    }
}
