import React, { useState, useCallback, useRef } from 'react';
import { useCookies } from 'react-cookie'
import TaskCard, {Task, TaskStatus} from "./Task";
import { TaskDashboardManager } from "./TaskDashboard"
import '../styles/Tasks.css';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { deleteTask } from "./Loader/Persist"
import { ISubscribable } from './Subscription';
import { TaskListOptions } from './TaskList/TaskListOptions';

enum Filter {
    Created = "Created",
    Name = "Name",
    HabitType = "Habit Type",
    TaskStatus = "Task Status",
    Category = "Category"
}

// Define the TaskList class
export class TaskList {
    private _tasks: Task[];

    constructor(taskList: Task[]) {
        this._tasks = taskList;
    }

    createTask(userID: string): Task {
        const task = new Task();
        task.userID = userID;
        this.tasks.push(task);
        return task;
    }

    removeTask(removedTask: Task): void {
        const index = this.tasks.findIndex((task) => task.id === removedTask.id);
        if (index >= 0) {
            deleteTask(this.tasks[index]);
            this.tasks.splice(index, 1);
        }
    }

    get tasks(): Task[] { return this._tasks; }
}

interface ITaskListViewProps {
    taskList: TaskList,
    taskListUpdate: (updatedTasks: Task[]) => void,
    taskDashboard: TaskDashboardManager,
    activeTasks: Task[],
    setActiveTasks: (tasks: Task[]) => void
}

const TaskListView: React.FC<ITaskListViewProps> = (props: ITaskListViewProps) => {
    const { taskList, taskListUpdate, taskDashboard, activeTasks, setActiveTasks } = props;
    const [cookies, _] = useCookies(["user"]);

    const [checkedAll, setCheckedAll] = useState<boolean>(
        taskList.tasks.length > 0 && 
        taskList.tasks.length === taskDashboard.tasks.length
    );
    const [indeterminateAll, setIndeterminateAll] = useState<boolean>(false);
    const [sortOrder, setSortOrder] = useState<Filter>(Filter.Category);

    const [visibleStatus, setVisibleStatus] = useState<TaskStatus[]>(
        Object.keys(TaskStatus).map((val: string) => TaskStatus[val as keyof typeof TaskStatus])
    );

    const addTask = useCallback(() => {
        taskList.createTask(cookies.user);
        taskListUpdate([...taskList.tasks]);
    }, [taskList]);

    const deleteTask = useCallback((task: Task) => {
        taskList.removeTask(task);
        taskListUpdate([...taskList.tasks]);
    }, [taskList]);

    taskDashboard.addSubscriptionCallback(
        (eventSrc: ISubscribable | null) => {
            setActiveTasks([...taskDashboard.tasks]);
        },
        "taskList"
    )

    const toggleActiveTask = useCallback((task: Task) => {
        if (!taskDashboard.tasks.includes(task)) {
            taskDashboard.subscribeToTask(task);
        }
        else {
            taskDashboard.unsubscribeFromTask(task);
        }

        if (taskDashboard.tasks.length === 0) {
            setIndeterminateAll(false);
            setCheckedAll(false);
        }
        else if (taskDashboard.tasks.filter((task: Task) => visibleStatus.includes(task.status)).length === 
                taskList.tasks.filter((task: Task) => visibleStatus.includes(task.status)).length) {
            setIndeterminateAll(false);
            setCheckedAll(true);
        }
        else {
            setIndeterminateAll(true);
        }

        setActiveTasks([...taskDashboard.tasks]);
    }, [taskDashboard, taskList.tasks, activeTasks, setCheckedAll, setIndeterminateAll]);

    const toggleAllTasks = useCallback(() => {
        let toActive = activeTasks.length < taskList.tasks.length;
        taskList.tasks.forEach((task: Task) => {
            const isActive = activeTasks.indexOf(task) >= 0;
            if (isActive !== toActive) {
                toggleActiveTask(task);
            }
        });
    }, [taskList.tasks, checkedAll, indeterminateAll, activeTasks, setActiveTasks]);
        
    const updateSort = useCallback((filter: Filter) => {
        let comparator = null;

        switch (Filter[filter.toString() as keyof typeof Filter]) {
            case Filter.Name: {
                comparator = (t1: Task, t2: Task) => {
                    return (t1.title).localeCompare(t2.title);
                }
                break;
            }
            case Filter.HabitType: {
                comparator = (t1: Task, t2: Task) => {
                    return (t1.habitType.toString()).localeCompare(t2.habitType.toString());
                }
                break;
            }
            case Filter.TaskStatus: {
                comparator = (t1: Task, t2: Task) => (t1.status.toString()).localeCompare(t2.status.toString())
                break;
            }
            case Filter.Category: {
                comparator = (t1: Task, t2: Task) => (t1.category?.name || "").localeCompare(t2.category?.name || "")
                break;
            }
            default: {
                comparator = (t1: Task, t2: Task) => t1.created.getTime() - t2.created.getTime();
                break;
            }
        }
        taskList.tasks.sort(comparator);
        taskListUpdate([...taskList.tasks]);
    }, [taskList.tasks, sortOrder, setSortOrder]);

    return (
        <div className='taskListMain'>
            <div className='taskListActionBar'>
                <Button variant="contained" onClick={addTask} startIcon={
                    <AddIcon/>
                } sx={{ padding: 0 }}>
                  New Task
                </Button>
                <FormControlLabel
                  label="Check All"
                  control={
                    <Checkbox
                      checked={checkedAll}
                      indeterminate={indeterminateAll}
                      onChange={toggleAllTasks}
                      sx={{ color: "white", margin: "5px" }}
                    />
                  }
                />
                <TaskListOptions visibleStatus={visibleStatus} setVisibleStatus={setVisibleStatus} setSortOrder={updateSort}/>
            </div>
            {taskList.tasks.map((task, index) => 
                {
                    return visibleStatus.includes(task.status) ? 
                        <TaskCard
                            key={index}
                            task={task}
                            isActive={ taskDashboard.tasks.includes(task) }
                            onClick={ () => { toggleActiveTask(task) } }
                            onDelete={ deleteTask }/> : null;
                }
            )}
        </div>
    );
};

export default TaskListView;
