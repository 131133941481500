import React, { ReactNode, useState, useCallback, useEffect } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';

import Typography from '@mui/material/Typography';

import { ISubscriberCallback, ISubscribable, ISubscriber } from "./Subscription";

import {Task} from "./Task";
import {TaskEvent, TaskEventEditor} from "./TaskEvent";
import {CalendarControl} from "./Calendar/CalendarControl";

import ColorPicker, { ColorScheme } from "./utility/ColorPicker";
import { compareDates } from "./utility/Dates";

import '../styles/Dashboard.css';
import "../styles/App.css";
import { FrequencyGraph } from './Graph/FreqJ';
import { act } from 'react-dom/test-utils';

/**
 *
 **/

export class TaskDashboardManager implements ISubscriber {
    subscriptions: Task[];
    subscriptionCallbacks: Map<string, ISubscriberCallback>;
    _colorScheme: ColorScheme;

    constructor(tasks: Task[] = []) {
        this.subscriptions = tasks;
        this.subscriptionCallbacks = new Map();

        this._colorScheme = ColorScheme.TaskUnique;
    }

    /**
    *       Accessors / mutators
    **/
        get tasks(): Task[] { return this.subscriptions; }
        get colorScheme(): ColorScheme { return this._colorScheme; }
        set colorScheme(colorScheme: ColorScheme) { this._colorScheme = colorScheme; }

        private set tasks(tasks: Task[]) { this.subscriptions = tasks; }

    /**
     *      Subscriptions
     **/

        // Add an event source to listen to
        private _triggerCallbacks() {
            Array.from(this.subscriptionCallbacks.values()).forEach(
                (callback: ISubscriberCallback) => callback(null)
            );
        }

        public subscribeToTask(task: Task) {
            Array.from(this.subscriptionCallbacks.keys()).forEach((key: string) => {
                const callback = this.subscriptionCallbacks.get(key)!;
                task.subscribe(callback, key);
            });
            this.tasks.push(task);
            this._triggerCallbacks();
        }

        public unsubscribeFromTask(task: Task) {
            const index = this.tasks.indexOf(task, 0);
            if (index >= 0) {
                this.tasks.splice(index, 1);
            }
            this._triggerCallbacks();
        }

        public addSubscriptionCallback(callback: ISubscriberCallback, key: string): void {
            this.tasks.forEach((task: Task) => {
                task.subscribe(callback, key);
            });

            this.subscriptionCallbacks.set(key, callback);
        }

        public removeSubscriptionCallback(key: string): void {
            this.tasks.forEach((task: Task) => {
                task.unsubscribe(key);
            })
            this.subscriptionCallbacks.delete(key);
        }
}

interface ITaskDashboardProps {
    manager: TaskDashboardManager,
    activeTasks: Task[],
    setActiveTasks: (tasks: Task[]) => void
}

export const TaskDashboard: React.FC<ITaskDashboardProps> = (props: ITaskDashboardProps) => {
    const { manager, activeTasks, setActiveTasks } = props;

    const updateActiveTasks = useCallback((eventSrc: ISubscribable | null) => {
        setActiveTasks([...manager.tasks])
    }, [manager, manager.tasks, activeTasks, setActiveTasks])

    useEffect(() => {
        manager.addSubscriptionCallback(updateActiveTasks, "taskDashboard");
        return () => { manager.removeSubscriptionCallback("taskDashboard") }
    }, [manager]);

    return <>
        <CalendarControl tasks={activeTasks} />
        <div>
            <TaskEventLog manager={manager} tasks={activeTasks} />
        </div>
    </>;
}

interface ITaskEventLogEntryProps {
    taskEvent: TaskEvent,
}

export const TaskEventLogEntry: React.FC<ITaskEventLogEntryProps> = (props: ITaskEventLogEntryProps) => {
    const { taskEvent } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <div className="taskEventLogEntry" onClick={handleOpen}>
                <span className="editButton"> <EditIcon /> </span>
                <span className="eventLogEntryTitle">{ taskEvent.toString() }</span>
                <Typography variant="body1" className="eventLogEntryDesc" sx={{ fontSize: 20 }}>
                    {taskEvent.comment}
                </Typography>
            </div>
            { open && <TaskEventEditor taskEvent={taskEvent} open={open} handleClose={handleClose} /> }
        </>
    );
}

const TaskEventLog: React.FC<{manager: TaskDashboardManager, tasks: Task[]}> = ({manager, tasks}) => {
    const monthStartDate = new Date();
    monthStartDate.setDate(1);
    const monthEndDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 1);

    return <>
        {tasks.map(
            (task: Task, index: number) =>
                <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ backgroundColor: ColorPicker.getColor(task),
                        color: ColorPicker.getNeutralColor(task) }}
                    >
                    { task.title }
                    </AccordionSummary>
                    <AccordionDetails className="tasklogBody">
                    { task.history.length == 0 && "No data"}
                    <FrequencyGraph task={task}/>
                    { [...task.history].reverse().filter(
                        (event: TaskEvent) => 
                            compareDates(event.date, monthStartDate) >= 0 && compareDates(event.date, monthEndDate) < 0
                    ).map(
                        (event: TaskEvent, index: number) =>
                        <div key={index}>
                            <TaskEventLogEntry taskEvent={event}/>
                        </div>
                    )}
                    </AccordionDetails>
                </Accordion>
        )}
    </>;
}
