import React from 'react';

import "../styles/Assets.css";
import { isWhite } from '../components/utility/ColorPicker';

export interface IProgressBadgeProps {
    success: number,
    total: number,
    label: string,
    color: string,
    textColor: string
}

export const ProgressBadge: React.FC<IProgressBadgeProps> = (props: IProgressBadgeProps) => {
    const { color, textColor, label, success, total } = props;
    let bgColor;
    if ( isWhite(textColor) ) { bgColor = "#bbb"; }
    else { bgColor = "#fff"; }
    return <div className="assets_progressBadgeContainer" 
                style={{ 
                    background: bgColor,
                    color: textColor, 
                    border: `2px solid ${color}`
                }}>
        <div className='assets_progressBadgeProgress' 
            style={{ backgroundColor: color,
            width: `${Math.round(success/total * 100)}%`}}></div>
        <div className='assets_progressBadgeTitle'>{label}</div>
        <div className='assets_progressBadgeCount'>{`${success}/${total}`}</div>
    </div>;
}