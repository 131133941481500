import React, { useRef } from 'react';

    import SendIcon from '@mui/icons-material/Send';
    import DeleteIcon from '@mui/icons-material/Delete';
    import ClearIcon from '@mui/icons-material/Clear';

    import Button from '@mui/material/Button';
    import TextField from "@mui/material/TextField";
    import CardActions from '@mui/material/CardActions';
    import CardContent from '@mui/material/CardContent';
    import Box from '@mui/material/Box';
    import Modal from '@mui/material/Modal';

    import FormControlLabel from '@mui/material/FormControlLabel';
    import Switch from '@mui/material/Switch';

    import { DateTimePicker } from '@mui/x-date-pickers';
    import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
    import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {Task, TaskMeasurement} from "./Task";
import { saveEvent } from "./Loader/Persist";
import "../styles/Tasks.css";
import "../styles/App.css";
import dayjs from "dayjs";
import { CardHeader } from '@mui/material';

export interface ITaskEvent {
    quantity: number,
    date: Date,
    comment: string,
    completed: boolean
}

export class TaskEvent implements ITaskEvent{
    private _id?: string;
    private _task: Task;
    private _quantity: number;
    private _date: Date;
    private _comment: string;
    private _completed: boolean;

    constructor(task: Task, date: Date = new Date(), quantity: number = 1, comment: string = "", 
            completed?: boolean, id?: string) {
        this._task = task;
        this._quantity = quantity;
        this._date = date;
        this._comment = comment;
        this._id = id;
        if (completed === undefined) {
            this._completed = date <= new Date();
        }
        else { 
            this._completed = completed; 
        }
    }

    get id(): string | undefined { return this._id; }
    set id(id: string | undefined) { this._id = id; }

    get date(): Date { return this._date; }
    set date(date: Date) { this._date = date; }

    get quantity(): number { return this._quantity; }
    set quantity(quantity: number) { this._quantity = quantity; }

    get task(): Task { return this._task; }

    get comment(): string {return this._comment;}
    set comment(comment: string)  { this._comment = comment;}

    get completed(): boolean {return this._completed;}
    set completed(completed: boolean)  { this._completed = completed;}

    public async save() {
        const retID = await saveEvent(this);
        console.log("EVENTSAVE");
        if (!this.id) { this.id = retID; }
    }

    public toString(): string {
        return this.date.toLocaleString('en-US', {
            weekday: 'long', // "Monday"
            year: 'numeric', // "2024"
            month: 'long', // "June"
            day: 'numeric', // "5"
            hour: 'numeric', // "1 PM"
            minute: 'numeric', // "1:30 PM"
            // second: 'numeric' // "1:30:15 PM"
        });
    }
}

interface ITaskEventEditorProps {
    taskEvent: TaskEvent,
    open: boolean,
    handleClose: () => void
}

export const TaskEventEditor: React.FC<ITaskEventEditorProps> = (props: ITaskEventEditorProps) => {
    const { taskEvent, open, handleClose } = props;

    const completedRef = useRef<HTMLInputElement>();
    const quantityRef = useRef<HTMLInputElement>();
    const commentRef = useRef<HTMLInputElement>();
    const dateEditRef = useRef<HTMLInputElement>(null);

    const updateCallback = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        taskEvent.completed = completedRef.current?.checked ?? false;
        taskEvent.quantity = parseInt(quantityRef.current?.value ?? "1");
        taskEvent.date = new Date(dateEditRef.current!.value);
        taskEvent.comment = commentRef.current?.value ?? "";
        taskEvent.task.sortHistory();
        taskEvent.task.notify();
        taskEvent.save();
        handleClose();
    }

    const deleteCallback = () => {
        taskEvent.task.deleteEvent(taskEvent);
        taskEvent.task.notify();
        handleClose();
    }
    
    return (<Modal
            open={open}
            onClose={handleClose}
          >
        <Box className="modalBox" sx={{
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
            <form onSubmit={updateCallback} className='taskEventEditor'>
                <CardHeader title={taskEvent.task.title}
                    subheader={taskEvent.date.toLocaleDateString(
                        'en-US',
                        { month: 'long', day: 'numeric', year: 'numeric'}
                    )}
                    className="taskEventEditorHeader"
                />
                <CardContent className="taskEventModal">
                    { taskEvent.task.measurement === TaskMeasurement.Count && <TextField
                        required
                        label="Value"
                        type="number"
                        defaultValue={taskEvent.quantity}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        inputRef={quantityRef}
                    />}
                    <FormControlLabel control={<Switch defaultChecked={taskEvent.completed} />} label="Completed?" inputRef={completedRef} />
                    <TextField className="taskEditField" type="text" multiline rows={3} inputRef={commentRef}
                        defaultValue={taskEvent.comment} label="Comment"/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Due"
                            defaultValue={dayjs(taskEvent.date)}
                            inputRef={dateEditRef}
                        />
                    </LocalizationProvider>
                </CardContent>
                <CardActions >
                    <div className='taskEventEditorActions'>
                        <Button size="small" type="submit" variant="outlined" startIcon={<SendIcon />}>
                            Save
                        </Button>
                        <Button size="small" variant="outlined" startIcon={<ClearIcon />} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button size="small" variant="outlined" startIcon={<DeleteIcon />} onClick={deleteCallback}>
                            Delete
                        </Button>
                    </div>
                </CardActions>
            </form>
        </Box>
      </Modal>
  );
}
