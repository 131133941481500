export interface LoginResponse {
    success: boolean,
    data?: string
    error?: string
}

export interface ILoginArgs {
    username: string,
    password: string
}

export async function TryLogin (loginData: ILoginArgs): Promise<string> {
    const successData = await fetch(`/api/login`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData)
    }).then((res) => res.json())
      .then((data) => {
        return data.data;
    });
    if (!successData || !successData.length) { return ""; }
    return successData[0].id;
}

export async function TryRegister (registrationData: ILoginArgs): Promise<boolean> {
    const success = await fetch(`/api/register`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData)
    }).then((res) => res.json())
      .then((data) => {
        return data.status === "SUCCESS";
    });
    return success;
}
